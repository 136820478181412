import { cn } from "@/common/helper";

interface IFooter {
	isSticky?: boolean | null;
}

const Footer = ({ isSticky = true }: IFooter): JSX.Element => {
	return (
		<footer
			className={cn(
				"py-4 text-center bottom-0 self-center w-full",
				isSticky ? "xl:fixed xl:left-0 xl:w-1/2" : "xl:static" // Footer does not need sticky logic anymore
			)}
		>
			<span className="text-grey-500 text-sm">
				© PT. Influencer Marketing Indonesia
			</span>
			<p className=" text-grey-500">
				<a
					href="https://api.whatsapp.com/send/?phone=6287797700999&text&type=phone_number"
					className="text-grey-500 hover:underline mx-2 text-sm"
					target="_blank"
					rel="noopener noreferrer"
				>
					Help Center
				</a>
				|
				<a
					href="/privacy-policy"
					className="text-grey-500 hover:underline mx-2 text-sm"
					target="_blank"
					rel="noopener noreferrer"
				>
					Privacy Policy
				</a>
				|
				<a
					href="/terms-and-conditions"
					className="text-grey-500 hover:underline mx-2 text-sm"
					target="_blank"
					rel="noopener noreferrer"
				>
					Terms and Conditions
				</a>
			</p>
		</footer>
	);
};

export default Footer;
