import axiosInstance from "@/config/axios";
import type { InstagramComplete, InstagramPublic } from "@/types/instagram";
import type {
	AnalyticsSource,
	City,
	Province,
	SocialMediaPlatform,
} from "@/types/master";
import type { TikTokComplete, TikTokPublic } from "@/types/tiktok";
import axios from "axios";
import type { UUID } from "node:crypto";

export const getCities = async (): Promise<Array<City>> => {
	const { data } = await axios.get<Array<City>>(
		`${import.meta.env["VITE_BASE_URL_API"]}/general/cities`
	);

	return data;
};

export const getProvince = async (): Promise<Array<Province>> => {
	const { data } = await axios.get<Array<Province>>(
		`${import.meta.env["VITE_BASE_URL_API"]}/general/provinces`
	);

	return data;
};

export const postSocialMediaPublic = async (parameters: {
	username: string;
	platform: SocialMediaPlatform;
}): Promise<InstagramPublic | TikTokPublic> => {
	const baseURL = `${import.meta.env["VITE_BASE_URL_API"]}/public/${parameters.platform}`;
	const url = new URL(baseURL);

	// Ensure type-safe access to parameters
	url.searchParams.append("username", parameters.username);

	const { data } = await axios.post<InstagramPublic | TikTokPublic>(
		url.toString()
	);
	return data;
};

export const getInstagramPostImage = async (parameters: {
	url: string;
}): Promise<string> => {
	const { data } = await axios.get<string>(
		`${import.meta.env["VITE_BASE_URL_API"]}/public/image`,
		{ params: parameters }
	);
	return data;
};

export const postSocialMediaRegistered = async (parameters: {
	username: string;
	platform: SocialMediaPlatform;
}): Promise<InstagramPublic | TikTokPublic> => {
	const baseURL = `${import.meta.env["VITE_BASE_URL_API"]}/social-media/${parameters.platform}/basic`;
	const url = new URL(baseURL);

	// Ensure type-safe access to parameters
	url.searchParams.append("username", parameters.username);

	const { data } = await axiosInstance.post<InstagramPublic | TikTokPublic>(
		url.toString()
	);
	return data;
};

export type ListItem = {
	platform: SocialMediaPlatform;
	uuid: UUID;
	username: string;
	name: string;
	avatarUrl: string;
	progress: number;
	attemptsMade: number;
	searchType: "ER_CALC" | "ANALYTICS";
	updatedAt: Date;
};

export type ListAnalytics = {
	data: Array<ListItem>;
	maxPage: number;
	currentPage: number;
	totalData: number;
	readyCount: number;
	preparingCount: number;
	failedCount: number;
};
export const getListRecentAnalytics = async (parameters: {
	page: number;
	limit: number;
	sortBy: string;
	sortType: "asc" | "desc";
	status?: number;
	platform?: SocialMediaPlatform;
}): Promise<ListAnalytics> => {
	const { data } = await axiosInstance.get<ListAnalytics>(
		`${import.meta.env["VITE_BASE_URL_API"]}/social-media/list/recent`,
		{ params: { ...parameters } }
	);
	data.data.forEach((element) => {
		element.platform = element.platform.toLowerCase() as SocialMediaPlatform;
	});
	return data;
};

export type AnalysisRequestParameters = {
	page: number;
	limit: number;
	sortBy: string;
	sortType: "asc" | "desc";
	status?: string;
	platform?: SocialMediaPlatform;
};

export const getListRequestAnalytics = async (
	parameters: AnalysisRequestParameters
): Promise<ListAnalytics> => {
	const { data } = await axiosInstance.get<ListAnalytics>(
		`${import.meta.env["VITE_BASE_URL_API"]}/social-media/list/request`,
		{ params: { ...parameters } }
	);
	data.data.forEach((element) => {
		element.platform = element.platform.toLowerCase() as SocialMediaPlatform;
	});
	return data;
};

export type FreeCreators = {
	name: string;
	platform: SocialMediaPlatform;
	username: string;
	profilePicture: string;
};
export const getFreeCreatorList = async (): Promise<Array<FreeCreators>> => {
	const { data } = await axiosInstance.get<Array<FreeCreators>>(
		`${import.meta.env["VITE_BASE_URL_API"]}/social-media/free-creators`
	);
	return data;
};

export type QueueAnalyzeResponse = {
	status: "ON_QUEUE" | "FAILED_TO_QUEUE" | "ON_PROGRESS" | "QUEUE_SUCCESS";
	dataAvailable: boolean;
	uniqueID: string;
	error?: string;
};
export const analyzeInstagram = async (parameters: {
	username: string;
	analyticsSource: AnalyticsSource;
}): Promise<QueueAnalyzeResponse> => {
	const baseURL = `${import.meta.env["VITE_BASE_URL_API"]}/social-media/instagram`;
	const url = new URL(baseURL);

	// Ensure type-safe access to parameters
	(Object.keys(parameters) as Array<keyof typeof parameters>).forEach((key) => {
		url.searchParams.append(key, parameters[key]);
	});

	const { data } = await axiosInstance.post<QueueAnalyzeResponse>(
		url.toString()
	);
	return data;
};
export const analyzeTikTok = async (parameters: {
	username: string;
	analyticsSource: AnalyticsSource;
}): Promise<QueueAnalyzeResponse> => {
	const baseURL = `${import.meta.env["VITE_BASE_URL_API"]}/social-media/tiktok`;
	const url = new URL(baseURL);

	// Ensure type-safe access to parameters
	(Object.keys(parameters) as Array<keyof typeof parameters>).forEach((key) => {
		url.searchParams.append(key, parameters[key]);
	});

	const { data } = await axiosInstance.post<QueueAnalyzeResponse>(
		url.toString()
	);
	return data;
};

export const analyzeInstagramBasic = async (parameters: {
	username: string;
	analyticsSource: AnalyticsSource;
}): Promise<QueueAnalyzeResponse> => {
	const baseURL = `${import.meta.env["VITE_BASE_URL_API"]}/social-media/instagram/basic`;
	const url = new URL(baseURL);

	// Ensure type-safe access to parameters
	(Object.keys(parameters) as Array<keyof typeof parameters>).forEach((key) => {
		url.searchParams.append(key, parameters[key]);
	});

	const { data } = await axiosInstance.post<QueueAnalyzeResponse>(
		url.toString()
	);
	return data;
};
export const analyzeTikTokBasic = async (parameters: {
	username: string;
	analyticsSource: AnalyticsSource;
}): Promise<QueueAnalyzeResponse> => {
	const baseURL = `${import.meta.env["VITE_BASE_URL_API"]}/social-media/tiktok/basic`;
	const url = new URL(baseURL);

	// Ensure type-safe access to parameters
	(Object.keys(parameters) as Array<keyof typeof parameters>).forEach((key) => {
		url.searchParams.append(key, parameters[key]);
	});

	const { data } = await axiosInstance.post<QueueAnalyzeResponse>(
		url.toString()
	);
	return data;
};

export const getAnalyticsDetail = async (parameters: {
	userSearchUUID: UUID;
	platform: SocialMediaPlatform;
}): Promise<TikTokComplete | InstagramComplete | null> => {
	try {
		const baseURL = `${import.meta.env["VITE_BASE_URL_API"]}/social-media/${parameters.platform}/${parameters.userSearchUUID}`;
		const url = new URL(baseURL);

		const { data } = await axiosInstance.get<
			TikTokComplete | InstagramComplete
		>(url.toString());

		return data;
	} catch (error) {
		if (axios.isAxiosError(error)) {
			console.error("API Error:", error.response?.data);
			return null;
		}
		return null;
	}
};
