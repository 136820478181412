import VerifiedIcon from "@/assets/icons/verified-icon.svg";
import InstaIcon from "@/assets/icons/instagram-icon.svg";
import TiktokIcon from "@/assets/icons/tiktok-icon.svg";
import type { FreeCreators } from "@/services/master-services";
import { getFreeCreatorList } from "@/services/master-services";
import { useFeaturedCreatorStore } from "@/store/featured-creator-store";
import type { AnalyticsSource, SocialMediaPlatform } from "@/types/master";
import { useQuery } from "@tanstack/react-query";
import {
	ChevronLeftIcon,
	ChevronRightIcon,
	LoaderCircleIcon,
} from "lucide-react";
import { useEffect, useState } from "react";
import { cn, shuffle } from "@/common/helper";
interface FeaturedCreatorsProps {
	handleSearchUser: (
		username: string,
		platform: SocialMediaPlatform,
		status: AnalyticsSource
	) => void;
}
const FeaturedCreators: React.FC<FeaturedCreatorsProps> = ({
	handleSearchUser,
}) => {
	// listCreators = [...listCreators, ...listCreators, ...listCreators];
	const [currentPage, setCurrentPage] = useState(1);
	const itemsPerPage = 10;

	const listFreeCreatorStore = useFeaturedCreatorStore();
	const [selectedPlatform, setSelectedPlatform] = useState<
		Array<SocialMediaPlatform>
	>(["instagram", "tiktok"]);
	const [listCreators, setListCreators] = useState(Array<FreeCreators>);
	const { data: defaultListCreator, isPending: isListFreeCreatorPending } =
		useQuery({
			queryKey: [`get-list-free-creators`],
			queryFn: async () => {
				const resp = await getFreeCreatorList();
				listFreeCreatorStore.setFreeCreatorList(
					shuffle(resp) as Array<FreeCreators>
				);
				return resp;
			},
			retry: 1,
			refetchOnWindowFocus: false,
		});

	useEffect(() => {
		if (!defaultListCreator) return;
		const filteredCreators =
			selectedPlatform.length === 0 || selectedPlatform.length === 2
				? defaultListCreator
				: defaultListCreator.filter((x) =>
						selectedPlatform.includes(x.platform)
					);

		setListCreators(filteredCreators);
		setCurrentPage(1);
	}, [selectedPlatform, defaultListCreator]);
	if (!defaultListCreator) return <></>;

	const totalPages = Math.ceil(listCreators.length / itemsPerPage);

	const renderFreeCreators = (): Array<JSX.Element> => {
		const startIndex = (currentPage - 1) * itemsPerPage;
		const endIndex = startIndex + itemsPerPage;

		const selectedCreators = listCreators.slice(startIndex, endIndex);
		return selectedCreators.map((c, index) => (
			<div
				key={`${c.username}-${c.platform}-${index}`}
				className="flex flex-wrap justify-between items-center my-4"
			>
				<div className="flex items-center gap-2">
					<div className="w-9 h-9 rounded-full">
						<img
							src={c.profilePicture}
							alt={c.name}
							className="w-9 h-9 object-cover rounded-full"
						/>
					</div>
					<div className="flex flex-col">
						<div className="flex items-center gap-1">
							<span className="text-grey-800 font-semibold">{c.name}</span>
							<div>
								<img src={VerifiedIcon} alt="verif-icon" />
							</div>
						</div>
						<span className="text-sm text-grey-400">
							{c.platform === "instagram" ? "Instagram" : "TikTok"}
						</span>
					</div>
				</div>

				<div>
					<button
						className="flex justify-between gap-2 items-center text-orange-700 font-medium py-1 px-2 pl-3 w-full border border-orange-300 focus:outline-none hover:bg-orange-200 rounded-2xl"
						onClick={() => {
							handleSearchUser(c.username, c.platform, "VERIFIED");
						}}
					>
						<span>Analyze</span>
						<span className="flex items-center text-[14px] py-1 px-2 text-white font-medium justify-center bg-orange-500 rounded-2xl">
							FREE
						</span>
					</button>
				</div>
			</div>
		));
	};

	const handlePreviousPage = (): void => {
		setCurrentPage((previousPage) => Math.max(previousPage - 1, 1));
	};

	const handleNextPage = (): void => {
		setCurrentPage((previousPage) => Math.min(previousPage + 1, totalPages));
	};

	const togglePlatform = (platform: SocialMediaPlatform): void => {
		setSelectedPlatform((previousSelected) =>
			previousSelected.includes(platform)
				? previousSelected.filter((item) => item !== platform)
				: [...previousSelected, platform]
		);
	};
	return (
		<div>
			<div className="flex flex-row justify-between">
				<span className="font-semibold text-grey-800">Featured Creators</span>
				<div className="flex gap-2 mt-[-6px]">
					<div
						className={cn(
							"cursor-pointer p-2 border-2 border-gray-300 rounded-lg",
							selectedPlatform.includes("instagram")
								? "border-orange-600 border-2"
								: ""
						)}
						onClick={() => {
							togglePlatform("instagram");
						}}
					>
						<img src={InstaIcon} alt="insta-icon" />
					</div>
					<div
						className={cn(
							"cursor-pointer p-2 border-2 border-gray-300 rounded-lg",
							selectedPlatform.includes("tiktok")
								? "border-orange-600 border-2"
								: ""
						)}
						onClick={() => {
							togglePlatform("tiktok");
						}}
					>
						<img src={TiktokIcon} alt="tiktok-icon" />
					</div>
				</div>
			</div>
			{isListFreeCreatorPending && (
				<div className="flex justify-center items-center fixed top-0 left-0 w-full h-full bg-white border opacity-75 z-50">
					<div className="flex justify-center items-center">
						<LoaderCircleIcon className="animate-spin" size={50} />
					</div>
				</div>
			)}
			{renderFreeCreators()}
			<div className="flex justify-end items-center mt-4 gap-1">
				{currentPage > 1 && (
					<button
						className="w-8 h-8 flex justify-center items-center hover:bg-orange-200 rounded-full group "
						onClick={handlePreviousPage}
						disabled={currentPage === 1}
					>
						<ChevronLeftIcon className="text-gray-500 group-hover:text-orange-500" />
					</button>
				)}
				<span className="w-8 h-8 flex justify-center items-center rounded-full text-white bg-orange-500">
					{currentPage}
				</span>
				{currentPage < totalPages && (
					<button
						className="w-8 h-8 flex justify-center items-center hover:bg-orange-200 rounded-full group"
						onClick={handleNextPage}
						disabled={currentPage === totalPages}
					>
						<ChevronRightIcon className="text-gray-500 group-hover:text-orange-500" />
					</button>
				)}
			</div>
		</div>
	);
};

export default FeaturedCreators;
