import LandingPageImage from "@/assets/images/landing-page.png";
import SearchBar from "@/components/forms/search-bar";
import { Layout } from "@/components/layout";
import Footer from "@/components/layout/Footer";
import type { SocialMediaPlatform } from "@/types/master";
import { useNavigate } from "@tanstack/react-router";
import {
	GaugeIcon,
	PieChartIcon,
	PresentationIcon,
	SparklesIcon,
} from "lucide-react";
import type { FunctionComponent } from "../common/types";

export const Home = (): FunctionComponent => {
	const navigate = useNavigate();

	const handleSearchUser = (
		searchQuery: string,
		selectedOption: SocialMediaPlatform
	): void => {
		console.log(searchQuery, selectedOption, "SEARCH ");
		navigate({
			to: `/public/search`,
			search: { username: searchQuery, platform: selectedOption },
		}).catch((error) => {
			console.error(error);
		});
	};

	return (
		<Layout withSidebar={false} showFooter={false}>
			<div className="flex flex-col xl:flex-row w-full h-full ">
				<div className="flex basis-1 grow flex-col my-auto justify-center items-center  md:gap-8 xl:py-8">
					<div className="flex flex-col w-[80%] mx-4 gap-4">
						<h1 className="font-semibold text-4xl text-center xl:text-left">
							Get Your Creator Game On Point
						</h1>
						<span className="text-lg text-grey-50  text-center xl:text-left">
							Optimize your influencer marketing performance
							<br className="hidden sm:block" />
							by utilizing the right creator for the brand
						</span>

						<SearchBar
							onSearchUserBasic={handleSearchUser}
							defaultOption="instagram"
						/>
					</div>

					<div className="flex flex-col md:flex-row xl:flex-col gap-4 md:gap-0 xl:gap-4 w-[80%] mt-4 md:mt-0 md:mb-4 flex-wrap">
						<div className="flex items-center bg-white text-grey-500 gap-4 rounded-lg p-2 md:basis-6/12">
							<div className="p-1 border-orange-700 border bg-white rounded">
								<GaugeIcon color="#E68C24" />
							</div>

							<span className="">Engagement Rate Calculator</span>
						</div>

						<div className="flex items-center bg-white text-grey-500  gap-4 rounded-lg p-2 md:basis-6/12">
							<div className="p-1 border-primary-500 border bg-white rounded">
								<PresentationIcon color="#359FD9" />
							</div>

							<span className="">Creator Performance Analysis</span>
						</div>

						<div className="flex items-center bg-white text-grey-500  gap-4 rounded-lg p-2 md:basis-6/12">
							<div className="p-1 border-green-400 border bg-white rounded">
								<PieChartIcon color="#14A085" />
							</div>

							<span className="">Audience Demographic Analysis</span>
						</div>

						<div className="flex items-center bg-white text-grey-500  gap-4 rounded-lg p-2 md:basis-6/12">
							<div className="p-1 border-orange-500 border bg-white rounded">
								<SparklesIcon color="#FAA61A" />
							</div>

							<span className="">Creator Lookalike</span>
						</div>
					</div>
					<div className="hidden xl:block">
						<Footer></Footer>
					</div>
				</div>

				<div className="hidden md:flex basis-0 xl:basis-1 grow-0 xl:grow h-full justify-center">
					<img
						src={LandingPageImage}
						alt="landing-image"
						className={`object-cover w-full rounded h-[calc(100vh-90px)]`}
					/>
				</div>
			</div>
			<div className="xl:hidden">
				<Footer></Footer>
			</div>
		</Layout>
	);
};
