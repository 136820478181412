import tokenServices from "@/services/token-services";
import axiosInstance from ".";

interface RefreshTokenResponse {
	accessToken: string;
}
export const refreshToken = async (): Promise<string> => {
	try {
		const localRefreshToken = tokenServices.getLocalRefreshToken();
		if (localRefreshToken) {
			const response = await axiosInstance.post<RefreshTokenResponse>(
				"/auth/refresh-tokens",
				{
					refreshToken: tokenServices.getLocalRefreshToken(),
				}
			);
			const newAccessToken = response.data.accessToken;

			// Store newAccessToken
			return newAccessToken;
		}
		return "";
	} catch (error) {
		console.error("Error refreshing token:", error);
		// Handle error (e.g., logout user)
		tokenServices.removeUser();
		window.location.replace("/");
		throw error;
	}
};
