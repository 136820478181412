import { downloadInvoice } from "@/services/payment-services";
import type { CreditInfo } from "@/services/subscription-services";
import { clsx, type ClassValue } from "clsx";
import dayjs from "dayjs";
import { twMerge } from "tailwind-merge";

// Define the cn function with an explicit return type of string
export function cn(...inputs: Array<ClassValue>): string {
	return twMerge(clsx(inputs));
}

// Define the debounce function with specific types for fn and return type
export const debounce = <T extends Array<unknown>>(
	fn: (...args: T) => void,
	delay: number
): ((...args: T) => void) => {
	let timeoutId: NodeJS.Timeout;
	return (...args: T): void => {
		clearTimeout(timeoutId);
		timeoutId = setTimeout(() => {
			fn(...args);
		}, delay);
	};
};

// Define the formatNumber function with explicit return type of string
export const formatNumber = (
	number_: number,
	precision: number = 2
): string => {
	const nf = new Intl.NumberFormat("en-US", {
		maximumFractionDigits: precision,
	});
	return nf.format(number_);
};

export const formatNumberCompact = (
	number_: number,
	precision: number = 2
): string => {
	const map = [
		{ suffix: "T", threshold: 1e12 },
		{ suffix: "B", threshold: 1e9 },
		{ suffix: "M", threshold: 1e6 },
		{ suffix: "K", threshold: 1e3 },
		{ suffix: "", threshold: 1 },
	];

	const found = map.find((x) => Math.abs(number_) >= x.threshold);
	if (found) {
		if (found.suffix == "") {
			precision = 0;
		}
		const formatted =
			(number_ / found.threshold).toFixed(precision) + found.suffix;
		return formatted;
	}

	return number_.toFixed(0);
};

// Define the formatToIDR function with explicit return type of string
export const formatToIDR = (amount: number): string => {
	return new Intl.NumberFormat("id-ID", {
		style: "currency",
		currency: "IDR",
		minimumFractionDigits: 0,
		maximumFractionDigits: 0,
	}).format(amount);
};

export const hasAnalyticCredit = (creditInfo: CreditInfo | null): boolean => {
	if (
		creditInfo &&
		creditInfo.analyticsLimit - creditInfo.analyticsUsage > 0 &&
		creditInfo.status === "ACTIVE"
	)
		return true;
	return false;
};
export const hasEmptyAnalyticCredit = (
	creditInfo: CreditInfo | null
): boolean => {
	if (
		creditInfo &&
		creditInfo.analyticsLimit > 0 &&
		creditInfo.analyticsLimit == creditInfo.analyticsUsage &&
		creditInfo.status === "ACTIVE"
	)
		return true;
	return false;
};

export const hasERCredit = (creditInfo: CreditInfo | null): boolean => {
	if (creditInfo && creditInfo.erLimit - creditInfo.erUsage > 0) return true;
	return false;
};

export const isFirstTimeUsingERCalc = (
	creditInfo: CreditInfo | null
): boolean => {
	if (
		creditInfo &&
		creditInfo.erUsage == 0 &&
		creditInfo.analyticsLimit &&
		creditInfo.analyticsLimit > 0 &&
		creditInfo.analyticsUsage &&
		creditInfo.analyticsLimit == creditInfo.analyticsUsage
	)
		return true;
	return false;
};

export const handleDownload = async (invoiceId: number): Promise<void> => {
	try {
		// Call the API function to download the invoice PDF
		const pdfBlob = await downloadInvoice(invoiceId);

		// Create a URL for the Blob
		const url = window.URL.createObjectURL(pdfBlob);

		// Create a link element and trigger download
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute(
			"download",
			`invoice-${invoiceId == -1 ? dayjs().format("YYYYMMDDHHmm") : invoiceId}.pdf`
		);
		document.body.append(link);
		link.click();

		// Clean up: remove the link and revoke the URL
		link.remove();
		window.URL.revokeObjectURL(url);
	} catch (error) {
		console.error("Error downloading PDF:", error);
		// Handle error, e.g., show an error message to the user
	}
};
export function openInNewTab(url: string): void {
	const win: Window | null = window.open(url, "_blank");
	if (win) win.focus();
}
export function shuffle(array: Array<unknown>): Array<unknown> {
	let currentIndex = array.length;

	// While there remain elements to shuffle...
	while (currentIndex != 0) {
		// Pick a remaining element...
		const randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}
	return array;
}
