import { Header } from "@/components/layout";
import "./styles.css";
const TermsAndConditions = (): JSX.Element => {
	return (
		<>
			<Header refreshKey={0}></Header>
			<div className="bg-gray-100 text-gray-800 py-10">
				<div className="max-w-4xl mx-auto py-10 px-6 bg-white shadow-lg rounded-lg">
					<h1 className="text-3xl font-semibold mb-6 text-center">
						Terms and Conditions for Creator Analytics by Influence ID
					</h1>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">Introduction</h2>
						<p>
							Welcome to Creator Analytics by Influence ID. These Terms and
							Conditions ("Terms") govern your use of our services. By accessing
							or using our platform, you agree to comply with and be bound by
							these Terms.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">Services Provided</h2>
						<p>
							Creator Analytics by Influence ID offers a subscription-based
							influencer marketing platform that allows brands and agencies to
							search for creators on Instagram and TikTok. Additional services
							may include the purchase of add-on credit for enhanced features.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Subscription and Add-On Credit
						</h2>
						<h3 className="text-lg font-semibold mb-1">Subscription Model</h3>
						<ul className="list-disc list-inside mb-4">
							<li>Users can subscribe to our services on a monthly basis.</li>
							<li>
								Subscription fees are non-refundable, except as required by law.
							</li>
							<li>
								Subscriptions will automatically renew unless canceled prior to
								the renewal date.
							</li>
						</ul>
						<h3 className="text-lg font-semibold mb-1">Add-On Credit</h3>
						<ul className="list-disc list-inside">
							<li>
								Users can purchase add-on credit to access additional features
								and services.
							</li>
							<li>Add-on credit are non-refundable and non-transferable.</li>
							<li>
								Add-on credit must be used within the specified period; unused
								credit will expire.
							</li>
						</ul>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Non-Convertibility of Analytics Credits
						</h2>
						<p>
							Analytics credits cannot be redeemed for cash or converted into
							any form of currency. They are exclusively usable for purchasing
							services within the Creator Analytics by Influence ID platform.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">Cancellation Process</h2>
						<p>
							Users may cancel their subscription through the subscription page
							at any time before the subscription's renewal date. After clicking
							the cancellation option, our customer service team will contact
							you shortly to confirm the cancellation. The process will be
							completed within a maximum of 3 working days.
						</p>
					</section>
					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Effect of Cancellation
						</h2>
						<p>
							Please note that cancellations made after the subscription renewal
							date will apply to the next billing cycle. No refunds will be
							issued for any unused portion of the subscription period unless
							required by law.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">Taxes</h2>
						<p>
							All fees and charges for our services are subject to applicable
							taxes, which will be added to your total billing amount. You are
							responsible for paying all taxes associated with your subscription
							and add-on credit.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Payment Terms and Tax Invoices
						</h2>
						<p>
							Users requiring payment terms or tax invoices are requested to
							submit their request through the provided form at this link. All
							requests will be processed promptly and efficiently.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">Account Registration</h2>
						<h3 className="text-lg font-semibold mb-1">Eligibility</h3>
						<p>
							To use our services, you must be at least 18 years old or have
							reached the age of majority in your jurisdiction.
						</p>
						<h3 className="text-lg font-semibold mb-1">Account Information</h3>
						<p>
							You agree to provide accurate, current, and complete information
							during the registration process. You are responsible for
							maintaining the confidentiality of your account credentials and
							for all activities that occur under your account.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">User Conduct</h2>
						<h3 className="text-lg font-semibold mb-1">
							Prohibited Activities
						</h3>
						<ul className="list-disc list-inside mb-4">
							<li>
								You agree not to use the platform for any unlawful purpose or in
								any way that could harm our platform or its users.
							</li>
							<li>
								You agree not to engage in any activity that interferes with or
								disrupts the platform's functionality.
							</li>
							<li>
								Your website does not contain:
								<ul className="list-disc list-inside ml-5">
									<li>Child pornography</li>
									<li>Offers for illegal drugs or tobacco products</li>
									<li>Gambling</li>
									<li>
										Sale of products that violate Intellectual Property Rights
									</li>
									<li>Violence and related content</li>
									<li>
										Any other content that violates moral norms and applicable
										laws
									</li>
								</ul>
							</li>
						</ul>
						<h3 className="text-lg font-semibold mb-1">Content Use</h3>
						<p>
							You agree not to reproduce, distribute, or create derivative works
							from any content accessed through our platform without our
							permission.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Data Collection and Use
						</h2>
						<h3 className="text-lg font-semibold mb-1">Public Data</h3>
						<p>
							We collect publicly available data from social media platforms,
							such as Instagram and TikTok, and analyze it to provide insights
							and analytics to our users.
						</p>
						<h3 className="text-lg font-semibold mb-1">Personal Data</h3>
						<p>
							We collect personal information, such as name, email address, and
							billing information, to provide and maintain our services.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Intellectual Property
						</h2>
						<h3 className="text-lg font-semibold mb-1">Ownership</h3>
						<p>
							All content and materials on our platform, including trademarks,
							logos, and service marks, are the property of Influence ID or its
							licensors. You agree not to use any of our intellectual property
							without our prior written consent.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Limitation of Liability
						</h2>
						<h3 className="text-lg font-semibold mb-1">No Warranty</h3>
						<p>
							Our services are provided "as is" and "as available" without any
							warranties, express or implied. We do not guarantee that our
							services will be uninterrupted, error-free, or secure.
						</p>
						<h3 className="text-lg font-semibold mb-1">
							Limitation of Liability
						</h3>
						<p>
							To the fullest extent permitted by law, Influence ID will not be
							liable for any indirect, incidental, special, consequential, or
							punitive damages arising out of or in connection with your use of
							our services.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">Termination</h2>
						<h3 className="text-lg font-semibold mb-1">Termination by You</h3>
						<p>
							You may terminate your account at any time by contacting
							support@influence.id or +62 877-9770-0999.
						</p>
						<h3 className="text-lg font-semibold mb-1">Termination by Us</h3>
						<p>
							We may terminate or suspend your account and access to our
							services at our sole discretion, without prior notice, if you
							violate these Terms.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Additional Requirements
						</h2>
						<h3 className="text-lg font-semibold mb-1">
							Complete Description of the Goods or Services Offered
						</h3>
						<p>
							Creator Analytics by Influence ID offers digital products,
							including analytics credit and subscription services that provide
							access to influencer marketing analytics and insights.
						</p>
						<h3 className="text-lg font-semibold mb-1 mt-4">
							Transaction Currency
						</h3>
						<p>All transactions are processed in Indonesian Rupiah (IDR).</p>
						<h3 className="text-lg font-semibold mb-1 mt-4">
							The Prices of Each Item
						</h3>
						<p>
							The prices for each item are clearly listed on our website and
							during the checkout process.
						</p>
						<h3 className="text-lg font-semibold mb-1 mt-4">
							The Total Price of the Order, Including Shipping Fees and Other
							Applicable Fees
						</h3>
						<p>
							The total price of your order, including any applicable fees, will
							be displayed during the checkout process before you confirm your
							purchase. There are no shipping fees for digital products.
						</p>
						<h3 className="text-lg font-semibold mb-1 mt-4">
							Billing and Shipping Address
						</h3>
						<p>
							When you purchase any products or services from Creator Analytics
							by Influence ID, you will be required to provide your billing
							address. The invoice will be generated to the address that the
							user fills out and can be edited in the settings.
						</p>
						<h3 className="text-lg font-semibold mb-1 mt-4">Delivery Policy</h3>
						<p>
							Creator Analytics by Influence ID delivers digital products, such
							as analytics credit and subscriptions, electronically. Once the
							payment is processed, the credit and subscription services will be
							activated immediately and available in your account.
						</p>
						<section className="mb-6 mt-6">
							<h2 className="text-xl font-semibold mb-2">
								The Expected Delivery Date of the Order
							</h2>
							<p>
								<strong>Digital Products:</strong> Instant delivery upon
								successful payment processing. You will receive a confirmation
								email, and the credit will be added to your account immediately.
							</p>
							<p>
								<strong>Physical Products:</strong> Not applicable, as all our
								products and services are digital.
							</p>
						</section>

						<section className="mb-6">
							<h2 className="text-xl font-semibold mb-2">
								Return/Refund Policy
							</h2>
							<p>
								We strive to ensure our customers are satisfied with their
								purchases. However, if you encounter any issues, please refer to
								our return and refund policy below:
							</p>
							<ul className="list-disc list-inside ml-6">
								<li>
									<strong>Refund Requests:</strong> Requests for refunds must be
									made within 7 days of purchase. Please contact our support
									team at{" "}
									<a
										href="mailto:support@influence.id"
										className="text-blue-500 hover:underline"
									>
										support@influence.id
									</a>{" "}
									or +62 877-9770-0999 with your order details and the reason
									for the refund request.
								</li>
								<li>
									<strong>Eligibility:</strong> Refunds are granted if there is
									a technical issue that prevents you from using the purchased
									credit or subscription, and our support team cannot resolve
									the issue.
								</li>
								<li>
									<strong>Processing Time:</strong> Refunds will be processed
									within 14 business days from the date of approval. The
									refunded amount will be credited back to the original method
									of payment.
								</li>
								<li>
									<strong>Non-refundable Items:</strong> Used analytics credit,
									partially used subscriptions, and expired credit are
									non-refundable.
								</li>
							</ul>
						</section>

						<section className="mb-6">
							<h2 className="text-xl font-semibold mb-2">Restriction</h2>
							<p>
								The services offered by Creator Analytics by Influence ID are
								available to users who are 18 years of age or older. We reserve
								the right to refuse service to anyone for any reason at any
								time.
							</p>
						</section>

						<section className="mb-6">
							<h2 className="text-xl font-semibold mb-2">
								Visa and MasterCard Brand Mark
							</h2>
							<p>
								We accept Visa and MasterCard for payment processing. All
								transactions are securely processed through our payment gateway.
							</p>
						</section>

						<section className="mb-6">
							<h2 className="text-xl font-semibold mb-2">
								Address of Merchant’s Permanent Establishment
							</h2>
							<p>
								PT Influencer Marketing Indonesia
								<br />
								Gedung Office 8, Level 18A, Sudirman Central Business District
								(SCBD), Lot 28, Jl. Jend. Sudirman Kav. 52-53, Desa/Kelurahan
								Senayan, Kec. Kebayoran Baru, Kota Adm. Jakarta Selatan
							</p>
						</section>

						<section className="mb-6">
							<h2 className="text-xl font-semibold mb-2">
								Disclosure of the Merchant Outlet Country at the Time of
								Presenting Payment Options to the Cardholder
							</h2>
							<p>
								All transactions are processed in Indonesia. The merchant outlet
								country is Indonesia.
							</p>
						</section>

						<section className="mb-6">
							<h2 className="text-xl font-semibold mb-2">
								Customer Service Contact
							</h2>
							<p>
								For any questions or assistance, please contact our customer
								service team:
								<br />
								Email:{" "}
								<a
									href="mailto:support@influence.id"
									className="text-blue-500 hover:underline"
								>
									support@influence.id
								</a>
								<br />
								Phone: +62 877-9770-0999
							</p>
						</section>
					</section>
					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Customer Data Privacy Policy
						</h2>
						<p>
							We are committed to protecting your privacy and ensuring that your
							personal information is handled in a safe and responsible manner.
							This policy outlines the types of personal information we collect,
							how we use it, and the measures we take to safeguard it.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Security Capabilities and Policy for Transmission of Payment Card
							Details
						</h2>
						<p>
							We use Secure Socket Layer (SSL) technology to encrypt your
							payment card details during transmission to ensure your
							information is safe and secure.
						</p>
						<p>
							By using our services, you agree to our Terms and Conditions and
							Privacy Policy. A "Click to Accept" button is provided during the
							checkout process to ensure you have read and accepted these
							policies.
						</p>
						<p>
							We use SSL technology to ensure secure transmission of your data.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Website Content Restrictions
						</h2>
						<p>Our website does not contain:</p>
						<ul className="list-disc list-inside ml-5">
							<li>Child pornography</li>
							<li>Offers for illegal drugs or tobacco</li>
							<li>Gambling</li>
							<li>
								Sale of products that infringe on intellectual property rights
							</li>
							<li>Violence and similar content</li>
							<li>
								Other content that is contrary to public decency and applicable
								laws
							</li>
						</ul>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">
							Changes to These Terms
						</h2>
						<p>
							We may update these Terms from time to time. We will notify you of
							any changes by posting the new Terms on this page. Your continued
							use of the platform after any changes constitutes your acceptance
							of the new Terms.
						</p>
					</section>

					<section className="mb-6">
						<h2 className="text-xl font-semibold mb-2">Contact Us</h2>
						<p>
							If you have any questions about these Terms, please contact us at:
							<br />
							Email: support@influence.id
							<br />
							Phone: +62 877-9770-0999
						</p>
					</section>
				</div>
			</div>
		</>
	);
};

export default TermsAndConditions;
